.wrapper
  border: 1px solid #e0e0e0
  background: #fff
  padding: 30px

.menu
  font-size: 16px

.card
  background: #fafafa !important
  border: 1px solid #d6d6d6
  border-radius: 8px !important
  margin-top: 20px

.cardHeader
  display: flex
  flex: auto
  flex-direction: row
  align-items: center
  justify-content: space-between

  .cardTitle
    display: flex
    flex-direction: row
    align-items: center
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.2px
    line-height: 30px
    gap: 10px

.cardDetails
  border-top: 1px solid #e0e0e0
  color: #707070
  margin-left: 90px
  padding-top: 10px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

.cardLastStatus
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

.cardStatusMessage
  display: flex
  flex-direction: row
  gap: 10px
  align-items: center
  justify-content: left
  width: 400px
  color: #707070

.cardEllipsis
  font-size: 24px
  margin-bottom: 11px
  padding: 0
  font-weight: bold
  color: #000000
  cursor: pointer
  border: none
  background: none

.carDetailsTable
  table-layout: fixed
  width: 650px
  text-align: left

.carDetailsTableError
  table-layout: fixed
  width: 400px

.emptyDataSyncList
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  align-items: center
  margin: 0 0 0 15px
  padding: 20px
  background: #F6F9FB
  font-size: 17px
  \:global
    .bp5-icon
      margin: 15px
      color: #ddd
  .info
    font-size: .8em

.listTop
  display: flex
  border-bottom: 1px solid #e0e0e0
  justify-content: space-between
  padding-bottom: 20px
  align-items: center

.searchInput
  width: 400px
  max-width: 400px

.filtersSection
  display: flex
  flex-direction: row
  align-items: center
  gap: 10px

.lastError
  color: red
  word-break: break-word
