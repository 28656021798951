@import "src/style/brand-vars"
@import "src/style/Mixins"
.list-item
  padding: 5px 0px
  &.dragging
    transition: transform 250ms ease-out
    .drag-handle
      cursor: grabbing
  &.being-dragged
    opacity: 0
    pointer-events: none
  .drag-handle
    cursor: grab
