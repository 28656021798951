@import "~@optimizely/design-tokens/dist/scss/colors"

.appLog
  flex-grow: 1
  margin: 0 0 0 15px
  min-height: 600px

.error 
  color: red

.timestamp
   font-weight: bold  

.emptyAppLog
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  align-items: center
  margin: 0 0 0 15px
  padding: 20px
  background: #F6F9FB
  font-size: 17px
  \:global
    .bp5-icon
      margin: 15px
      color: #ddd
  .info
    font-size: .8em
.log-messages
  max-height: 400px
  overflow-y: auto
  display: grid
  grid-template-columns: auto 1fr
  border-radius: 3px
  border: 1px solid $block-list-divider-color
  .log-messages-items
    display: grid
    grid-template-columns: auto 1fr
  & :global 
    span
      display: inline-block
      &:last-child
        display: inline
    .log-line
      padding-bottom: 6px
      padding-left: 4px
      .log-datetime
        font-weight: 600
        width: 200px
      .log-warn, .log-error, .log-info, .log-debug
        font-weight: 600
        margin: 0 4px
        width: 74px
      .log-debug
        color: $brand-primary-color
      .log-warn
        color: $yellow-base
      .log-error
        color: $red-base
    .log-index-col
      color: $brand-text-secondary-color
      display: flex
      justify-content: center
      align-items: flex-start
      padding: 0 10px
      padding-bottom: 10px
      background-color: $brand-background-disabled-color
      &:first-child
        padding-top: 10px
        & + div
          padding-top: 10px
    .log-content-col
      line-height: 1.2
    [class*=LoadingIndicator_container]
      min-height: 60px