@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.container
  color: $text-black
  display: flex
  font-weight: 500

.title
  margin-right: 10px
  line-height: 30px
  color: rgba(57,57,57,0.5)
  font-size: 13px
  font-style: normal

.option
  font-size: 13px
  line-height: 18px

.sortByButton
  composes: option

.tooltip
  \:global
    .#{$ns}-button:not(.#{$ns}-minimal)
      .#{$ns}-icon
        color: $icon-base-color
