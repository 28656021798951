.container
  background: #FAFAFA
  padding: 24px
  border: 1px solid #EDEDED
  border-radius: 8px
  width: 45%

.fullWidthContainer
  background: #FAFAFA
  padding: 24px
  border: 1px solid #EDEDED
  border-radius: 8px
  width: 100%

.syncName
  width: 45%
