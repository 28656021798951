.markdown
  h1
    font-size: 26px
  h2
    font-size: 21px
  h3
    font-size: 18px
  h4
    font-size: 16px
  h5
    font-size: 15px
  h6
    font-size: 15px

  p
    margin-bottom: 1.3em

  p:last-child
    margin-bottom: 0

  ol, ul
    li
      padding: 3px 0

  img
    max-width: 100%
    margin: 20px 0

  pre
    padding-left: 20px
    margin: 30px 0
    background: rgb(245, 245, 245)
    padding: 15px

  *:not(pre) code
    background: rgb(245, 245, 245)
    padding: 0 3px

  strong
    font-weight: 600

  blockquote
    font-size: .95em
    opacity: .75
    border-left: 4px solid rgba(0,0,0,.5)
    padding: 10px 20px
    background: rgba(0,0,0,.05)

  table
    border-spacing: 0px
    width: 100%
    th
      text-align: left
      border-bottom: 1px solid black
      padding: 7px
    tr
      td
        padding: 7px
      &:nth-child(even)
        background-color: #f6f9fb
