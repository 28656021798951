@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"
$nested-menu-width: 240px

.container
  transition: height 250ms
  width: $nested-menu-width
  position: relative
  max-height: 475px // Should nicely cut off menu-items in the middle, making it obvious to scroll.
  display: flex
  flex-direction: column
  &.simple
    .item:first-of-type
      margin-top: 5px
    .item:before,
    .parent-item:before
      content: none
  .apply-button-wrapper
    display: flex

.apply-button
  display: flex
  flex: 1 0 auto

.menu
  padding: 0

.menu-container
  width: $nested-menu-width
  position: relative
  padding: 4px
  transition: left 250ms ease-in-out // Timing needs to match MENU_SLIDE_MS in the component.

.breadcrumbs
  padding: 0 6px

.menus
  position: relative
  display: block
  overflow: hidden
  flex-grow: 1

  \:global
    .#{$ns}-menu-item-label
      position: relative
      .#{$ns}-tag
        margin-right: 2px
        color: $brand-primary-color
        background: $brand-background-secondary-color

    .#{$ns}-breadcrumb
      font-size: 13px
      line-height: 19px
      font-weight: 500
      color: $brand-text-primary-color
      white-space: nowrap

    .slide-right-exit.slide-right-exit-active,
    .slide-left-enter
      left: 100% !important

    .slide-left-exit.slide-left-exit-active,
    .slide-right-enter
      left: -100% !important

    .slide-left-enter.slide-left-enter-active,
    .slide-right-enter.slide-right-enter-active
      left: 0 !important

    .slide-left-exit,
    .slide-right-exit
      position: absolute !important
      top: 0 !important
      left: 0 !important

.search
  margin: 5px
  input
    border-radius: 4px !important

.item,
.parent-item
  font-size: 13px
  font-weight: 500
  padding: 10px
  color: $brand-text-primary-color !important // fixes active color

.item:before,
.parent-item:before
  color: $icon-base-color
  margin-right: 10px

.go-back:before
  color: $brand-text-primary-color
  margin-right: 10px

.divider
  margin: 5px 0 !important

.actions
  flex-shrink: 0
  border-top: 1px solid $border-divider-color
  padding: 5px
  display: flex
  > *
    flex-grow: 1
  > * + *
    margin-left: 5px
