@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"
$min-width: 130px
$max-width: 400px
$max-height: 274px

// default in blueprint is 5px
$padding: 5px

.container
  min-width: $min-width
  max-width: $max-width

  .select-button
    min-width: $min-width
    max-width: $max-width
    justify-content: space-between
    \:global
      .#{$ns}-button-text
        flex-grow: 1
        min-width: 0
        text-align: left
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
      .#{$ns}-icon
        color: $icon-base-color

  &.inline
    min-width: auto
    max-width: none
    display: inline-block
    .select-button
      min-width: auto
      max-width: none

  \:global .#{$ns}-popover-target
    display: flex

\:global
  .h-DropDown-popover
    background: $brand-background-primary-color

    .#{$ns}-popover-content
      padding: $padding 0

    .#{$ns}-menu
      padding: 0 !important
      max-height: $max-height
      overflow: auto
      .#{$ns}-menu-item
        margin: 0 $padding
        > .#{$ns}-icon
          color: $icon-base-color
        &:hover:not(.#{$ns}-active)
          background-color: $brand-blue-light
        &:active:not(.#{$ns}-active)
          background-color: $brand-blue-base
        &.#{$ns}-active > .#{$ns}-icon
          color: $white

    .#{$ns}-input-group
      margin: $padding
      .#{$ns}-icon-search
        color: $icon-base-color
      .#{$ns}-input:not(:focus) + .#{$ns}-input-action .#{$ns}-button.#{$ns}-minimal:not(:hover)
        .#{$ns}-icon
          color: $gray !important
      .#{$ns}-input:focus + .#{$ns}-input-action .#{$ns}-button.#{$ns}-minimal:not(:hover)
        .#{$ns}-icon
          color: $gray !important
