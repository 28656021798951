.container
  display: flex

.left
  width: 400px
  min-width: 200px

.right
  padding-left: 40px
  min-width: 200px
  max-width: 500px

.help
  min-height: 30px
  margin-top: 19px
  padding: 2px 0 2px 15px
  border-left: 2px solid #D3D6D9
  font-size: 12px
  line-height: 15px
  color: rgba(57,57,57,0.65)

.noLabel .help
  margin-top: 2px
