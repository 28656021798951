@import "~@optimizely/design-tokens/dist/scss/colors"

.container
  opacity: 0
  animation: fade-in 200ms ease-out forwards

  \:global
    .bp5-tab-list 
      background: $brand-background-primary-color
      border-left: 1px solid rgba(16, 22, 26, .15)
      border-top: 1px solid rgba(16, 22, 26, .15)
      border-right: 1px solid rgba(16, 22, 26, .15)
    .bp5-tab-indicator-wrapper
      display: none
    .bp5-tab-indicator-wrapper ~ .bp5-tab
      &:last-child
        border-top-right-radius: 3px
      color: $brand-text-primary-color
      margin-right: 0
      padding: 10px 40px
      background-color: white !important
      border-bottom: none
      &[aria-selected="true"]
        overflow: unset
        cursor: default
        position: relative
        &::after
          content: ""
          display: block
          height: 3px
          background: $link-default-text-color
          position: absolute
          left: 0
          right: 0
          bottom: -3px
      &[aria-disabled="true"]
        border-color: transparent
        background-color: $brand-background-disabled-color !important
        color: $brand-text-disabled-color
    .bp5-tab-indicator-wrapper + .bp5-tab
       border-top-left-radius: 3px
    .bp5-tab-panel
      margin: 0

.panel
  padding: 40px
  padding-top: 20px
  width: 100%
  background: white
  border: 1px solid rgba(16, 22, 26, .15)
  border-radius: 0 3px 3px 3px
  min-height: 500px
  display: grid
  grid-template-rows: 1fr

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1
