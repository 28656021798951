@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.header-container
  display: flex
  align-items: baseline
  min-height: 30px
  \:global .h-DropDown
    min-width: auto
  h3, h4
    line-height: 30px

  .header-prefix, .header-suffix
    margin: 0
    font-weight: 300 !important
  .header-prefix
    margin-right: .2em
  .header-suffix
    margin-left: .2em


  .header-text
    padding: 0 5px
    margin: 0

  .header-dropdown-button
    padding: 0 5px
    font-weight: 500 !important
    box-shadow: 0 2px $brand-blue-base
    .header-text
      padding: 0 0
    &:hover, &:active
      box-shadow: 0 2px $brand-blue-base
    \:global .#{$ns}-icon
      color: inherit
