@import "src/style/brand-vars"
@import "src/style/Mixins"
$padding: 20px
$icon-padding: 7px
$spacing: 7px
$horizontal-padding: 43px
$min-width: 600px

.container
  padding: 0
  margin-bottom: $spacing
  min-width: $min-width
  background-color: $brand-background-primary-color
  &.minimal
    min-width: auto
    margin-bottom: 0

.header
  padding: 0 $padding
  min-height: 54px
  display: flex
  justify-content: space-between
  align-items: center
  &:not(.disabled)
    cursor: pointer
  &.disabled .left
    padding-left: 23px
  &.minimal
    min-height: 46px
    padding: 0 10px 0 10px

  .left
    display: flex
    flex-grow: 1

    &.minimal
        color: $brand-text-primary-color
    h4,
    h5,
    h6
      margin: 0

    .icon
      display: flex
      align-items: center
      margin-right: $icon-padding

      svg
        transition: transform .25s
        transform-origin: center
    .open > svg
      transform: rotate(90deg)

  .right
    display: flex
    align-items: center
    max-width: 50%

    .helper-text
      color: $brand-text-secondary-color
      font-size: 14px
      font-weight: 500
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    .icon
      display: inline-flex
      margin-left: $icon-padding
      color: $icon-base-color

    .icon-tick-circle
      color: $icon-disabled-color

.body
  padding: 0 $horizontal-padding $padding $horizontal-padding
  &.minimal
    padding: 0 15px 15px 33px
