@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.container
  user-select: none
  padding: 5px
  border-radius: 3px
  display: inline-flex !important
  align-items: center
  justify-content: space-between
  &:hover
    background-color: $smoke-base
    \:global .#{$ns}-popover-wrapper
      opacity: 0.7
  \:global .#{$ns}-popover-wrapper
    height: 16px
    opacity: 0
    margin: 0 2px 0 3px
  \:global .#{$ns}-icon
    display: block
