@import '~@zaiusinc/hera/src/style/blueprint.sass'
@import '~@zaiusinc/hera/dist/hera.css'
@import '~@zaiusinc/app-forms/dist/app-forms.css'
@import "~@optimizely/design-tokens/dist/scss/colors"
@import "normalize.css"
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css"
@import "@blueprintjs/core/lib/css/blueprint.css"
@import "@blueprintjs/icons/lib/css/blueprint-icons.css"
@import "@blueprintjs/select/lib/css/blueprint-select.css"

body
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background: $smoke-extra-light
  color: rgb(57,57,57)
  margin: auto
  max-width: 1520px
  min-width: 1150px
  padding: 20px 0

*:focus
  outline: none

// In an iframe, a centered dialog may be off screen.
// This puts it at the top of the page, which works well for most situations.
.bp5-dialog-container
  align-items: flex-start
