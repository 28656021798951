@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.label
  color: $black
  \:global
    .#{$ns}-label
      font-family: $common-font-family
      font-size: 11px
      font-weight: 600
      text-transform: uppercase

    .#{$ns}-text-muted
      font-weight: 500
      color: $brand-text-secondary-color

.muted
  \:global
    .#{$ns}-label
      color: $brand-text-secondary-color
    .#{$ns}-text-muted
      color: $grey-base
