@import "@blueprintjs/core/lib/scss/variables"

@mixin stackedBarColors($color, $hover: darken($color, 10%), $otherSectionHovered: $hover)
  &:global(.bar-section)
    fill: $color
    background-color: $color
    color: $color
  &:global(.bar.hovered)
    fill: $otherSectionHovered
    color: $otherSectionHovered
  &:global(.bar-section.hovered)
    fill: $hover
    color: $hover

@mixin lineChartColor($color)
  &:global(.line)
    stroke: $color
    stroke-width: 3

@mixin barChartColor($color, $hover: darken($color, 10%))
  &:global(.bar)
    fill: $color
    \:global(.hovered)
      fill: $hover

@mixin legendChartColor($color)
  .legend-type-dot,
  .legend-type-bar,
  .legend-type-line
    background-color: $color 
  .legend-type-dashed-line
    border-color: $color

@mixin vertexChartColor($fill, $stroke: $fill, $hover: darken($fill, 10%), $hoverStroke: $hover)
  &:global(.line-vertex)
    fill: $fill
    stroke: $stroke
    &:global(.hovered),
    &:global(.x-axis-hovered)
      fill: $hover
      stroke: $hoverStroke

@mixin circleChartColor($fill, $hover: darken($fill, 10%), $stroke: $fill, $hoverStroke: $hover)
  &.circle
    fill: $fill
    stroke: $stroke
    &.hovered
      fill: $hover
      stroke: $hoverStroke
      stroke-width: 4

@mixin bpCheckboxColors($color)
  &:global.dot
      background-color: $color
  &:global.checked
    span.#{$ns}-control-indicator,
    &:hover span.#{$ns}-control-indicator
      background-color: $color
    &:hover
      span.#{$ns}-control-indicator
        background-color: $color
    input:not(:disabled):active:checked ~ .#{$ns}-control-indicator
      background-color: $color
  &:global.unchecked
    &:hover
      \:global input:indeterminate ~ .#{$ns}-control-indicator
        background-color: transparentize($color, 0.35)
    \:global
      span.#{$ns}-control-indicator
        background-color: transparentize($color, 0.35)
      input:not(:disabled):active:indeterminate ~ .#{$ns}-control-indicator
        background-color: transparentize($color, 0.35)

@mixin yaxisChartColor($color)
  &.y-axis .tick text
    fill: rgba($color, 0.65)
  .axis-label
    fill: $color
 
@mixin createChartColor($name, $color)
  .line-#{$name}
    @include lineChartColor($color)
  .bar-#{$name}
    @include barChartColor($color, darken($color, 10%))
  .circle-#{$name}
    @include circleChartColor(darken($color, 5%), darken($color, 15%))
  .y-axis-#{$name}
    @include yaxisChartColor($color)
  
@mixin createLegendColor($name, $color)
  .legend-entry-#{$name}
    @include bpCheckboxColors($color)
    @include legendChartColor($color)
