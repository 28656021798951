@import "src/style/brand-vars"
@import "src/style/Mixins"
.list-card
  display: flex
  align-items: top
  position: relative
  padding-left: 26px
.drag-handle
  position: absolute
  left: 5px
  top: 0
  bottom: 0
  display: flex
  align-items: center
