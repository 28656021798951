@import "src/style/brand-vars"
@import "src/style/Mixins"
.container
  display: inline-flex // Helps with vertical centering in some cases (campaign indicators).

.tag
  border-radius: 10px
  min-width: 20px
  height: 20px
  line-height: 17px // Helps with centering.
  text-align: center
  font-size: 11px
  font-weight: 600
  padding: 1px 4px 0 // 1px top also helps with centering.

  &.numeric
    font-size: 12px

