@import "src/style/brand-vars"
@import "src/style/Mixins"
.toggle
  outline: none
  min-height: 28px
  margin-left: -10px
  span:first-child
    transition: transform .25s
    transform-origin: center

.open
  .toggle span:first-child
    transform: rotate(90deg)

  .main
    margin-top: 10px
