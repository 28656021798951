@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"
$max-width-small: 180px
$max-width-large: 630px

.container
  display: flex
  justify-content: center
  flex-direction: row
  background-color: $brand-background-primary-color
  transition: max-width .15s ease-in-out
  max-width: $max-width-small

  &.range-picker-show
    max-width: $max-width-large

    .date-range-picker
      opacity: 1

  .date-range-picker
    transition: opacity .15s ease-in-out
    opacity: 0
    overflow: hidden
    .column
      display: flex
      flex-direction: column
      flex-basis: 100%
      flex: 1
      &.helper-text
        color: $brand-text-secondary-color
        margin: 0px 0px 10px 10px

  .date-range-options
    max-width: $max-width-small
    background-color: transparent

\:global
  .h-DatePicker
    .#{$ns}-datepicker .DayPicker-Day.DayPicker-Day--selected
      background-color: $brand-primary-color
    .#{$ns}-daterangepicker .DayPicker-Day--selected-range
      background-color: $brand-blue-light
    .#{$ns}-daterangepicker.#{$ns}-daterangepicker-contiguous .DayPicker
      min-width: 220px
    *:focus
      outline: none

  .h-DateDropdownPicker
    .spinner.#{$ns}-spinner .#{$ns}-spinner-head
      stroke: $icon-base-color
    .#{$ns}-button-text
      min-width: 160px
      text-align: left
    .button-icon
      color: $icon-base-color

  .#{$ns}-datepicker
    background: transparent
    min-height: 261px // some months have more rows than others; avoids vertical center shifting around

    .#{$ns}-icon
      color: $icon-base-color

  // #{$ns}-datepicker-caption-measure is used to position the drop arrow, so the
  // styles must match exactly for the placement to work
  .#{$ns}-datepicker .#{$ns}-datepicker-caption select, .#{$ns}-datepicker-caption-measure
    font-family: $common-font-family
    font-size: 14px
    font-weight: 600
    text-transform: none

  // Since we can't easily customize the icon used, the following hack
  // covers up the top part of the double arrow
  .#{$ns}-datepicker-caption select + .#{$ns}-icon
    height: 8px
    overflow: hidden
    margin-top: 5px
    > svg
      margin-top: -8px
