@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.container
  .disabled
    \:global
      input
        color: $brand-text-disabled-color

  \:global
    .#{$ns}-active.#{$ns}-intent-danger
      box-shadow: 0 0 0 1px $brand-error-color, 0 0 0 3px $red-base, inset 0 1px 1px rgba(16,22,26,.2)

