.breadcrumbs
  margin-bottom: 21px
  text-transform: uppercase
  font-size: 13px
  height: 14px
  line-height: 14px
  font-weight: 700
  a
    color: rgba(57,57,57,0.65)
  .current a
    color: #393939
  .chevron
    color: rgba(57,57,57,0.65)
    vertical-align: bottom

.main
  display: grid
  grid-template-columns: auto 1fr
  gap: 20px

.error
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  min-height: 420px
  font-size: 14px
  font-weight: 500
  .icon
    opacity: .45
    margin: 0 0 1rem
  .text
    opacity: .65
    margin: 0 0 1rem
