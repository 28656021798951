@import "src/style/brand-vars"
@import "src/style/Mixins"
\:global
  .h-Link
    color: $brand-primary-color
    text-decoration: none
    border-bottom: 1px solid $brand-primary-color
    cursor: pointer

    &__inactive
      color: $brand-text-secondary-color
      border-bottom-color: $border-user-input-color

    &__disabled
      color: $brand-text-disabled-color
      border-bottom-color: $border-disabled-color
      cursor: not-allowed
