.container
  background-color: #FFFFFF
  padding: 30px

.row
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 40px

.column
  display: flex
  flex-direction: column
  justify-content: space-between

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px

.actionButtons
  gap: 10px
  display: flex

.arrowRight
  display: flex
  align-items: center
