@import "src/style/brand-vars"
@import "src/style/Mixins"
.chart-tooltip
  position: absolute
  top: 0
  left: 0
  background: $brand-background-primary-color
  border: 1px solid $border-divider-color
  font-size: 15px
  color: $brand-text-primary-color
  padding: 8px
  border-radius: 3px
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.2)
  white-space: nowrap
  display: none

  \:global .dot
    height: 10px
    width: 10px
    margin: 0 10px 0 0
    background-color: $dark-gray
    border-radius: 50%
    display: inline-block

  &:after,
  &:before
    right: 100%
    top: 50%
    border: solid transparent
    content: " "
    height: 0
    width: 0
    position: absolute
    pointer-events: none

  &:after
    border-right-color: $brand-background-primary-color
    border-width: 10px
    margin-top: -10px

  &:before
    border-right-color: $border-divider-color
    border-width: 11px
    margin-top: -11px

  > div 
    display: flex
    flex-flow: column
