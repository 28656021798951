@import "~@zaiusinc/hera/src/style/brand-vars"

.container
  background: white
  border: 1px solid rgba(16, 22, 26, .15)
  border-radius: 3px
  width: 270px
  padding: 15px
  min-height: 331px
  opacity: 0
  animation: fade-in 300ms ease-out 150ms forwards

.row
  padding: 15px
  &:first-child
    padding-top: 0
  &:last-child
    padding-bottom: 0
  \:global
    .bp5-callout.bp5-intent-primary
      color: $brand-text-primary-color
      font-size: 12px

.logoMark
  height: 50px
  max-width: 150px
  object-fit: contain
  img
    height: 50px

.appName
  font-size: 18px
  font-weight: 500
  color: #393939
  line-height: 30px

.version
  padding-left: 5px
  font-size: 14px
  font-weight: 500
  opacity: .65

.vendor
  font-size: 14px
  font-weight: 500
  opacity: .65

.buttons
  margin-top: 9px
  display: flex
  justify-content: space-between
  .check
    padding-left: 10px
    padding-right: 6px

.divider
  width: 100%
  height: 1px
  background: #dddddd

.categories
  @extend .row
  line-height: 1.8em
  \:global
    .bp5-tag
      background: #E6ECEE
      color: #393939

.links
  @extend .row
  line-height: 26px
  > a
    display: block

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1

.note 
  padding: 15px 15px 0px 15px
  color: #5e6c84ff
