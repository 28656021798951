@import "src/style/brand-vars"
@import "src/style/Mixins"
$shadow-color: rgba(0, 0, 0, 0.05)
.container
  overflow-y: auto
  flex: auto
  &::before, &::after
    content: ''
    position: absolute
    transition: opacity 300ms 0ms linear
    height: 15px
    width: 100%
    pointer-events: none
    z-index: 1
  &::before
    top: 0
    left: 0
    right: 0
    background: linear-gradient($shadow-color, transparent)
  &::after
    bottom: 0
    left: 0
    right: 0
    background: linear-gradient(transparent, $shadow-color)
  &.bottom-shadow-hidden::after, &.top-shadow-hidden::before
    opacity: 0
    transition: 200ms 0ms ease-out
.shadow-container
  position: relative
  display: flex
  flex: auto
  overflow-y: auto
