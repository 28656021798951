@import "@optimizely/design-tokens/dist/scss/typography"
@import "@optimizely/design-tokens/dist/scss/colors"
// 2018 Style Guide

// Core Colors
$navy: $brand-purple-dark
$blue: $brand-primary-color
$green: $green-base
$red: $red-base
$orange: $orange-base

// Extended Colors
$link-blue: $brand-primary-color
$mist: $brand-background-secondary-color
$pink: $pink-base
$aqua: $aqua-blue-base
$purple: $purple-base

// Grayscale
$white: $neutral-colors-white
$black: $neutral-colors-black
$dark-gray: $grey-dark
$gray: $grey-base
$lightest-gray: $smoke-base
$light-gray: $smoke-dark
$off-white: $smoke-extra-light

// new for Datatable
$lighter-gray: $smoke-light
$darker-gray: $grey-base
// Text Specific
$text: $brand-text-primary-color
$text-black: $brand-text-primary-color
$text-light-gray: $brand-text-secondary-color
$text-disabled-gray: $brand-text-disabled-color
// Universal Background
$bg: $brand-background-secondary-color

// Popover (Tooltips and Some Dropdowns)
// Used by BP popover. optimizely/design-tokens/dist/scss/colors override them to be #c4c4c4
// Maybe we just need to update design tokens.
$tooltip-background-color: $neutral-colors-white
$tooltip-text-color: $brand-text-primary-color
$popover-background-color: $neutral-colors-white

// fonts
$common-font-family: $body-font-family, helvetica, sans-serif
