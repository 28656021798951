@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.container
  display: inline-block
  max-width: 100%
  .children-wrapper
    display: inline-block
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    max-width: 100%
  \:global
    .#{$ns}-popover-target
      max-width: 100%
