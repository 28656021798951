@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.paging
  display: flex
  align-items: center

  \:global
    input.#{$ns}-input
      width: 50px
      margin: 1px 4px 0 4px
      text-align: center
      font-size: 12px

  label
    margin: 0 5px 0 10px
    vertical-align: middle
    color: $brand-text-secondary-color
    font-size: 13px
    font-weight: 500
    text-align: right

  \:global
    .h-DropDown, .h-DropDown .#{$ns}-button
      min-width: 70px
