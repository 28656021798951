.row
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px

.source
  width: 45%

.destination
  width: 45%
  padding-left: 25px
  display: flex
  gap: 10px

.icon
  color: #393939

.iconDisabled
    color: lightgrey
