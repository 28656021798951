@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"
$cell-padding: 30px

.top,
.bottom
  height: 70px
  padding: 0 30px
  display: flex
  justify-content: space-between
  align-items: center

.top
  border-bottom: 1px solid $smoke-dark

.bottom
  justify-content: flex-end
  border-top: 1px solid $smoke-dark

.searchrow
  align-items: center
  display: flex
  flex-flow: row nowrap
  flex: auto
  \:global
    .h-SearchInput
      width: 100%
      max-width: 400px

.title
  display: inline-block
  vertical-align: middle
  text-transform: capitalize
  white-space: nowrap
  margin: 0 20px 0 0

.rightControls
  white-space: nowrap
  display: flex
  align-items: center
  > * + *
    margin-left: 10px

  button::before
    color: $text-black

  \:global
    .fixed-medium
      width: 100px

.placeholder
  min-height: 100px
  display: flex
  justify-content: center
  align-items: center
  font-style: italic

.noResults
  height: 275px
  background-color: $off-white
  width: 100%
  margin: 40px 40px 80px
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  font-size: 14px
  font-weight: 400
  font-style: normal

  span
    color: $text-disabled-gray
    text-align: center // needed for noColumnsText

  \:global .#{$ns}-icon-search
    color: $icon-disabled-color
    margin-bottom: 25px

  .subCopy
    color: $text-disabled-gray
    margin-top: 5px

.container
  position: relative // For loading indicator.
  border-radius: 2px
  background-color: $brand-background-primary-color
  &.has-border
    border: 1px solid $border-divider-color

  .scrollContainer::before
    margin-top: 40px

  table
    width: 100%
    border-collapse: collapse
    white-space: pre // Show the whitespace any values may have.
    &.fixed
      table-layout: fixed

  tr
    height: 40px
    border-bottom: 1px solid $smoke-light

  tr:last-child
    border-bottom: none

  .stickyHeader
    background-color: $brand-background-primary-color
    position: sticky
    top: 0
    z-index: 2
    &::after, &::before
      content: ''
      position: absolute
      width: 100%
      border-bottom: 1px solid $border-divider-color
      left: 0
    &::after
      bottom: 0
    &::before
      top: 0

  tbody tr
    font-size: 14px
    font-weight: 400

    &:nth-child(odd)
      background-color: $brand-background-primary-color

    &:hover
      background: $smoke-light

  th
    position: relative
    font-size: 10px
    font-weight: 600
    text-transform: uppercase
    text-align: left
    border-bottom: 1px solid $smoke-dark
    &:hover
      .delete-icon
        display: inline-block

  td
    font-size: 14px
    font-weight: 400
    word-break: break-word
    white-space: pre-wrap

  th,
  td
    padding-left: $cell-padding

    &.align-right
      text-align: right
      padding: 0 25px 0 5px

    &.align-left
      text-align: left
      padding: 0 5px 0 25px

    &:last-child
      padding-right: $cell-padding

    // Unfortunately the z-icon-rocket icon has extra whitespace on its right side.
    \:global .z-icon-rocket
      margin-right: 0

.column-icon
  color: $icon-base-color
  margin-right: 4px
  // Fix vertical centering
  position: relative
  top: 2px

.sort-icon
  margin-left: 4px
  margin-top: -3px
  vertical-align: middle

.sortable
  &:hover
    cursor: pointer
    background-color: $smoke-light

.delete-icon
  display: none
  position: absolute
  padding: 1px 4px 0
  left: 5px
  color: $text-light-gray
  &:hover
    color: $text-black
