.container
  background-color: #FAFAFA
  padding: 24px
  border: 1px solid #EDEDED
  border-radius: 8px
  width: 100%

.labels
  display: flex
  justify-content: space-between
  align-items: center

.sourceLabel
  width: 45%

.destinationLabel
  width: 45%
  padding-left: 25px
