@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.legend-container
  display: flex
  justify-content: center
  margin: 10px 0

.legend-entry
  cursor: default

.legend-entry-non-interactive 
  pointer-events: none
  cursor: pointer

.legend-type
  position: absolute
  top: calc(50% - 8px)
  left: 0
  height: 16px
  width: 16px
  
.legend-type-dot
  background-color: $dark-gray
  border-radius: 50%

.legend-type-dashed-line
  height: 4px
  top: calc(50% - 2px)
  background-color: transparent
  border-top: 4px dashed
  border-radius: 4px

.legend-type-line
  height: 4px
  top: calc(50% - 2px)
  background-color: $dark-gray
  border-radius: 4px

.legend-type-bar
  background-color: $dark-gray
  border-radius: 4px

.legend-outline
  border-radius: 3px
  box-shadow: 0 0 0 1px rgba(16,22,26,0.15)
  &:global.h-Label .#{$ns}-form-group
    margin: 5px 20px 0

.legend-label-wrapper
  &.horizontal
    display: inline-block
  &.vertical
    min-width: 180px
    display: block

