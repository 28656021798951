.container
  display: flex
  align-items: center
  justify-content: space-between
  color: #707070

.iconWrapper
  min-width: 30px
  display: flex
  justify-content: center
  align-items: center
