@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.metrics-list
  display: grid
  grid-column-gap: 10px
  grid-row-gap: 10px
  // Slightly overcomplicated here, but basically we want to fit 6 on a line, and have them use up consistent space no matter what.
  // 8.33 is required to compensate for grid-row-gap: 10px, since it's effectively adding 50px of extra width across 6 items (50 / 6 = 8.333)
  grid-template-columns: repeat(auto-fill, calc(16.666% - 8.333px))

.metric-tile
  flex: 0 0 auto
  min-width: 160px
  background: $smoke-light
  padding: 16px 20px 12px
  overflow: hidden

  .label
    color: $brand-text-secondary-color
    margin-bottom: 10px
    line-height: 12px

  .display
    display: flex
    flex-flow: row nowrap
    align-items: flex-end

  .additional-info
    display: flex
    align-items: center

  .value
    display: inline-block
    margin-bottom: 0
    height: 1em

  .suffix
    display: flex
    margin-bottom: 0
    margin-left: 6px
    line-height: 12px

  .trend
    margin-top: 8px
    display: flex
    line-height: 12px
    font-size: 11px
    color: $brand-text-primary-color
    &.good-trend
      color: $green-base
    &.bad-trend
      color: $brand-error-color
    &.no-trend
      color: $brand-text-secondary-color
    .trend-text
      margin-left: 4px
      font-weight: 600
    \:global(.#{$ns}-icon)
      margin-top: -2px
