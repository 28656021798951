@import "~@zaiusinc/hera/src/style/brand-vars"

.container
  font-size: 14px
  opacity: 0
  animation: fade-in 300ms ease-out forwards
  > h6
    margin: 0 0 10px 0
    padding: 0
    color: rgba(57,57,57,0.65)
    text-transform: uppercase
    font-weight: 700
    font-size: 11px

.listItem
  padding: 6px 10px
  line-height: 18px
  cursor: pointer
  &:hover
    color: $brand-primary-color

.selected
  color: $brand-primary-color
  background: $brand-blue-light !important

.divider
  margin: 10px 0
  border-bottom: 1px solid #ddd

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1
