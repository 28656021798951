@import "src/style/brand-vars"
@import "src/style/Mixins"
\:global
  .chart
    @include createChartColor('navy', $navy)
    @include createChartColor('blue', $blue)
    @include createChartColor('green', $green)
    @include createChartColor('red', $red)
    @include createChartColor('orange', $orange)
    @include createChartColor('deep-blue', $link-blue)
    @include createChartColor('mist', $mist)
    @include createChartColor('pink', $pink)
    @include createChartColor('aqua', $aqua)
    @include createChartColor('purple', $purple)
    .y-axis .tick text,
    .x-axis .tick text
      fill: $brand-text-secondary-color
    svg .y-axis-label
      font-weight: 700
      font-size: 11px
      line-height: 14px

  .legend,.chart-tooltip
    @include createLegendColor('navy', $navy)
    @include createLegendColor('blue', $blue)
    @include createLegendColor('green', $green)
    @include createLegendColor('red', $red)
    @include createLegendColor('orange', $orange)
    @include createLegendColor('deep-blue', $link-blue)
    @include createLegendColor('mist', $mist)
    @include createLegendColor('pink', $pink)
    @include createLegendColor('aqua', $aqua)
    @include createLegendColor('purple', $purple)

  .chart-theme-blue
    .multi-line-series,
    .stacked-bar-series
      .datum-0
        @include lineChartColor($blue)
        @include circleChartColor($blue)
        @include stackedBarColors($blue)
      .datum-1
        @include lineChartColor($link-blue)
        @include circleChartColor($link-blue)
        @include stackedBarColors($link-blue)
      .datum-2
        @include lineChartColor($purple)
        @include circleChartColor($purple)
        @include stackedBarColors($purple)
      .datum-3
        @include lineChartColor($aqua)
        @include circleChartColor($aqua)
        @include stackedBarColors($aqua)
      .datum-4
        @include lineChartColor($green)
        @include circleChartColor($green)
        @include stackedBarColors($green)
