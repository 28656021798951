@import "~@zaiusinc/hera/src/style/brand-vars"

.card
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  min-width: 300px
  // min-width: calc(100vh - 235px - 40px)
  height: 235px
  margin: 10px
  opacity: 0
  animation: fade-in 200ms linear 35ms forwards
  &:nth-child(2n)
    animation-delay: 60ms
  &:nth-child(3n)
    animation-delay: 75ms
  > div
    flex-grow: 0

.blankCard
  width: 100%
  min-width: 300px
  height: 225px
  margin: 10px
  opacity: 0

.logoMark
  height: 50px
  max-width: 150px
  img
    object-fit: contain
    max-width: 150px
    height: 50px

.appName
  display: flex
  align-items: center
  margin-top: 5px
  font-size: 18px
  font-weight: 500
  color: #393939
  height: 30px

.appSummary
  position: relative
  font-size: 14px
  color: rgba(57,57,57,.65)
  line-height: 18px
  height: 54px
  overflow: hidden

.spacer
  flex-grow: 1 !important

.upgrade
  font-size: 12px
  color: $brand-text-primary-color
  margin: 5px 5px 0 0
  align-items: center
  display: flex

.appCategories
  overflow: hidden
  max-height: 50px

  \:global
    .bp5-tag
      background: #E6ECEE
      color: #393939
      margin: 5px 5px 0 0

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1
