@import "src/style/brand-vars"
@import "src/style/Mixins"
@import "@blueprintjs/core/lib/scss/variables"

.image, \:global .h-datatable-image
  $border-width: 1px
  $img-width: 70px

  border: $border-width solid $border-divider-color
  display: flex
  justify-content: center
  border-radius: 2px
  width: $img-width + $border-width * 2
  height: $img-width + $border-width * 2
  background: $off-white
  img
    width: $img-width
    height: $img-width
  .placeholder-img
    display: flex
    align-items: center

.actions, \:global .h-datatable-actions
  // Flexbox and height are set here to help vertically center our icons.
  display: flex
  justify-content: flex-end
  height: 16px
  // This makes it possible to center align tooltips (used on SenderProfiles).
  \:global span.#{$ns}-popover-target
    height: 16px

  a
    color: $darker-gray
    cursor: pointer
    &:focus,
    &:hover
      color: $icon-base-color
    &:not(:last-child)
      margin-right: 12px
