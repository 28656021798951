@import "~@optimizely/design-tokens/dist/scss/colors"

.container
  display: grid
  grid-template-rows: auto 1fr
  gap: 20px
  min-width: 0
.headers
  display: flex
  justify-content: space-between
  gap: 20px
  align-items: flex-end
  \:global
    .h-SearchInput
      min-width: 270px
      margin-left: 5px
.header-left, .header-right
  display: flex
  align-items: flex-end
  gap: 20px
.header-right
  & :global
    button.bp5-button.bp5-intent-primary
      background-color: white
      color: $brand-primary-color
      border: 1px solid $brand-primary-color
      box-shadow: unset
      padding: 0 15px
      font-weight: 400
.header-left
  & :global
    .bp5-input-group
      box-sizing: border-box
      .bp5-input
        border: 1px solid $input-default-active-border-color
        border-radius: 5px
        box-shadow: none
        transition: border-color .3s
        vertical-align: middle
        line-height: 1.3
        &:focus
          cursor: text
          box-shadow: 0px 0px 3px $button-primary-active-background-color
      .bp5-icon.bp5-icon-search
        color: $brand-text-secondary-color
        top: 50%
        transform: translateY(-50%)
        margin: 0
        padding-left: 8px
.header
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 5px
  & :global
    .bp5-popover-wrapper
      height: 30px
      display: flex
      align-items: center
      .calendar-icon
        color: $brand-primary-color
      .bp5-popover-target p
        margin-bottom: 0

.filter
  font-size: 0.875rem
  color: $brand-text-primary-color
  font-weight: 400
  opacity: .65

.header-right
  .header
    align-items: flex-end
  & :global 
    .date-range-filter
      display: flex
      align-items: center
      color: $brand-primary-color
      &:hover
        cursor: pointer
      .date-value
        display: flex
        gap: 4px
      .start-date
        text-align: right
    button.bp5-button.bp5-intent-primary 
      background-color: white 
      color: $brand-primary-color 
      border: 1px solid $brand-primary-color 
      box-shadow: unset 
      padding: 0 15px 
      font-weight: 400
  .action-buttons
    display: flex
    gap: 10px
    .btn-disabled
      color: $brand-text-disabled-color
      border: 1px solid $brand-text-disabled-color
      padding: 0 15px
  .menu-buttons
    display: none
  @media only screen and (max-width: 1400px)
    .action-buttons
      display: none
    .menu-buttons
      display: block
.menu-popover
  & :global
    .bp5-menu
      .bp5-menu-item
        color: $brand-primary-color
        padding: 10px 10px
        .bp5-icon
          color: $brand-primary-color
.date-picker-popover
  .date-picker__container
    display: grid
    grid-template-columns: 200px auto
  & :global
    .bp5-menu
      padding-left: 16px
      .shortcut-selected
        background-color: $brand-primary-color
        color: white !important
      .bp5-menu-item
        color: $brand-primary-color
        padding: 8px 8px
        .bp5-icon
          color: $brand-primary-color
    .bp5-datepicker
      .DayPicker-Day.DayPicker-Day--selected-range
        background-color: $button-primary-hover-background-color
      .DayPicker-Day.DayPicker-Day--selected
        background-color: $brand-primary-color