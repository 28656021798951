.container
  background-color: #FFFFFF
  padding: 30px
  border: #10161A1A solid 1px

.header
  display: flex
  align-items: center
  justify-Content: space-between
  margin-bottom: 30px

.title
  font-size: 20px
  font-weight: 500
  line-height: 23px
  display: flex
  align-items: center
  gap: 10px

.card
  border-radius: 8px !important
  border: 1px #EDEDED solid
  margin-bottom: 10px
  align-items: center

.cardRow
  display: inline-flex

.cardDateTime
  line-height: 23px
  font-size: 16px
  font-weight: 500
  margin-right: 150px
  min-width: 250px

.cardStats
  display: flex
  align-items: center
  color: #707070
  gap: 10px

.cardStatusTag
  width: 120px
  text-align: center
  margin-right: 150px

.cardErrorMessage
  color: #911D1D

.emptyList
  display: flex
  flex-direction: column
  flex-grow: 1
  justify-content: center
  align-items: center
  margin: 0 0 0 15px
  padding: 20px
  background: #F6F9FB
  font-size: 17px
  \:global
    .bp5-icon
      margin: 15px
      color: #ddd
  .info
    font-size: .8em

.pagination
  display: flex
  align-items: center
  gap: 10px
  label
    display: none

.executionDetails
  display: flex
  flex-wrap: wrap

.detailItem
  white-space: nowrap
  margin-right: 10px

